import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRouter from './router/app-router';
import "bootstrap/dist/css/bootstrap.min.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return  <>
    <ToastContainer enableMultiContainer containerId={'TR'} position={toast.POSITION.TOP_RIGHT} />
    <ToastContainer enableMultiContainer containerId={'BC'} position={toast.POSITION.BOTTOM_CENTER} />
  <AppRouter />
 
  </>

}

export default App;
