import {
    BrowserRouter as Router,
    Route,
    Routes
  } from "react-router-dom";
import { lazy, Suspense } from "react";
import Header from "../layout/header/header";
import Footer from "../layout/footer/footer";
const HomePage = lazy(() => import('../page/home/home-page'));


const AppRouter = () => {
    return <>
        <Suspense fallback={<span>Loading....</span>}>
            <Router>
                <Routes>                 
                    <Route path="/" element={<HomePage />} />  
                    <Route path="/footer" element={<Footer />} />                                  
                </Routes>
            </Router>
        </Suspense>
        
    </>
}

export default AppRouter;